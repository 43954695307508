import { createApp } from 'vue'
import App from './App.vue'
import router from './router'



import axios from 'axios'



// Create App
let app = createApp(App)



// Axios
app.config.globalProperties.$http = axios
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true

axios.interceptors.request.use(async (config) => {
    config.headers['X-BB-Auth-Token'] = localStorage.getItem('BBAT')
    config.headers['X-BB-Device-Token'] = localStorage.getItem('BBDT')
    config.headers['X-BB-Period-Token'] = localStorage.getItem('BBPT')
    
    return config;
})

axios.interceptors.response.use(function (response) {
    if(response.data.newToken) {
        localStorage.setItem('BBAT', response.data.newToken)
    }

    if(response.data.newPeriodToken) {
        localStorage.setItem('BBPT', response.data.newPeriodToken)
    }

    if(response.data.noPeriodToken && router.currentRoute._value.meta.Menu !== 'Settings' && router.currentRoute._value.meta.Menu !== 'Login' && router.currentRoute._value.meta.Menu !== 'Register') {
        location.href = '/instellingen/lidmaatschap'
    }

    if(response.data.logout && (router.currentRoute.value.name !== 'Login')) {
        localStorage.clear()
        location.href = '/login?redirectUrl=' + location.href
    }

    return response;
}, function (error) {
    return Promise.reject(error);
})



// Moment
import moment from 'moment'
app.config.globalProperties.$moment = moment



// Prime
import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura';
app.use(PrimeVue, {
    // Default theme configuration
    theme: {
        preset: Aura,
        options: {
            prefix: 'p',
            darkModeSelector: '.my-app-dark',
            cssLayer: false
        }
    }
})



// Toast
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
app.use(Toast, {
    transition: "Vue-Toastification__fade"
})



// AutoComplete
import DisableAutocomplete from 'vue-disable-autocomplete';
app.use(DisableAutocomplete);



// Mount App
app.use(router).mount('#app')