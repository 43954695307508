<template>
    <div class="bar" v-if="this.$route.meta.Bar" :style="{ background: this.$route.meta.Bar.Background }">
        <div class="content">
            <span class="title" :class="{ stack: this.$route.meta.Stack }">
                <div class="icon" v-if="this.$route.meta.Bar.SVG" v-html="this.$route.meta.Bar.SVG"></div>
                {{ this.$route.meta.Bar.Title }}
            </span>
            <span class="subtitle">{{ this.$route.meta.Bar.Subtitle }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Bar'
}
</script>

<style scoped>
.bar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.bar .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0 75px;
    overflow: unset;
}

.bar .content .title {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 800px;
    margin: 0;
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    transition: .25s;
}

.bar .content .title.stack {
    margin: -35px 0 0 0;
}

.bar .content .title .icon {
    display: flex;
    align-items: center;
}

.bar .content .title .icon:deep(svg) {
    fill: #fff;
}

.bar .content .title .icon:deep(svg) {
    width: 38px;
    height: 38px;
}

.bar .content .subtitle {
    max-width: 800px;
    color: #fff;
}
</style>