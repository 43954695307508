<template>
    <div class="error">
        <span class="title">Oeps! Deze pagina bestaat niet.</span>
        <span class="subtitle">Het lijkt erop dat de pagina die je zoekt niet gevonden kan worden. Misschien is de link verouderd of verkeerd ingevoerd.</span>
    </div>
</template>

<script>
export default {
    name: '404'
}
</script>

<style scoped>
.error {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    height: 100%;
}

.error .title {
    font-size: 24px;
    font-weight: 600;
}

.error .subtitle {
    max-width: 50%;
    font-size: 20px;
    text-align: center;
}
</style>