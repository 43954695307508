<template>
    <div class="scan">
        <div class="tab" v-if="View == 'Information'">
            <div class="block">
                <span class="title">
                    Opdrachtnemer
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </span>
                <div class="group">
                    <span class="subtitle">Naam</span>
                    <input type="text" v-model="User.Name" disabled>
                </div>
                <div class="group">
                    <span class="subtitle">Kamer van Koophandel</span>
                    <input type="text" v-model="User.KVK" disabled>
                </div>
            </div>
            <div class="block">
                <span class="title">Opdrachtgever</span>
                <span class="notice" v-if="$route.params.name">Zoek hieronder {{ Client.Name }} op in het handelsregister van de Kamer van Koophandel.</span>
                <div class="group">
                    <span class="subtitle">Naam *</span>
                    <input type="text" class="interaction" v-model="Client.Name" @keydown.enter="SearchKVK">
                    <span class="button" @click="SearchKVK" :class="{ disabled: KVK.Loader }">Zoeken</span>
                </div>
                <div class="group" v-if="KVK.Items">
                    <span class="subtitle">Zoekresultaten</span>
                    <input v-if="KVK.Items.length > 10" type="text" class="search" placeholder="Zoeken in de resultaten..." v-model="KVK.Filters.global.value">
                    <DataTable v-model:filters="KVK.Filters" :value="KVK.Items" paginator :rows="10" :rowsPerPageOptions="[10, 25, 50]" stripedRows sortable sortField="Name" :sortOrder="1" selectionMode="single" dataKey="Identifier" @rowSelect="SelectKVK" :globalFilterFields="['Name', 'Street', 'City']">
                        <Column field="Name" header="Naam"></Column>
                        <Column field="ID" header="KVK"></Column>
                        <Column field="Street" header="Straat"></Column>
                        <Column field="City" header="Plaats"></Column>
                    </DataTable>
                </div>
                <div class="group">
                    <span class="subtitle">Kamer van Koophandel *</span>
                    <input type="text" v-model="Client.ID">
                </div>
                <div class="group half">
                    <span class="subtitle">Straat *</span>
                    <input type="text" v-model="Client.Street">
                </div>
                <div class="group half">
                    <span class="subtitle">Plaats *</span>
                    <input type="text" v-model="Client.City">
                </div>
                <div class="group" v-if="Client.IBAN">
                    <span class="subtitle">IBAN</span>
                    <input type="text" v-model="Client.IBAN" disabled>
                </div>
            </div>
            <div class="block" v-if="Duplicates.Items.length > 0">
                <span class="title">Duplicaten</span>
                <div class="group">
                    <span>Er zijn al scans gevonden op dit Kamer van Koophandel nummer. Selecteer een scan om deze te koppelen of start een nieuwe.</span>
                    <DataTable :value="Duplicates.Items" stripedRows sortable sortField="Created" :sortOrder="1" selectionMode="single" dataKey="Identifier" @rowSelect="ConnectAssessment">
                        <Column field="Name" header="Naam"></Column>
                        <Column field="KVK" header="KVK"></Column>
                        <Column field="Score" header="Score">
                            <template #body="slotProps">
                                {{ slotProps.data.Score }} / 100
                            </template>
                        </Column>
                        <Column field="Created" header="Datum">
                            <template #body="slotProps">
                                {{ this.$moment(slotProps.data.Created).format('DD-MM-Y') }}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
            <div class="footer paddingBottom">
                <span class="secondary">
                    Terug naar overzicht
                </span>
                <span class="primary" :class="{ enabled: Client.Name && Client.ID && Client.Street && Client.City }" @click="CheckAssessments">
                    {{ Duplicates.Button }}
                </span>
            </div>
        </div>
        <div class="tab" v-if="View == 'Questions'">
            <div class="question">
                <ProgressBar class="progress" :value="(Questions.Items.filter(e => e.Answer == ('Y') || e.Answer == ('N')).length / Questions.Items.length) * 100"></ProgressBar>

                <span class="bubble">Vraag {{ Questions.Index + 1 }}</span>
                <span class="question-title">{{ Questions.Items[Questions.Index].Title }}</span>
                <span class="question-text">{{ Questions.Items[Questions.Index].Text.replace('%CLIENT_NAME%', Client.Name).replace('%USER_NAME%', User.Name) }}</span>
                <div class="answers">
                    <span class="answer" :class="{ selected: Questions.Items[Questions.Index].Answer == 'Y' }" @click="Questions.Items[Questions.Index].Answer = 'Y'">Ja</span>
                    <span class="answer" :class="{ selected: Questions.Items[Questions.Index].Answer == 'N' }" @click="Questions.Items[Questions.Index].Answer = 'N'">Nee</span>
                </div>
                <div class="answers">
                    <span class="more" :class="{ active: Questions.Items[Questions.Index].InformationActive }" @click="Questions.Items[Questions.Index].InformationActive = !Questions.Items[Questions.Index].InformationActive">
                        Meer informatie
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><g><path id="Vector" d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                    </span>
                </div>
                <div class="information" v-if="Questions.Items[Questions.Index].InformationActive">
                    <span class="information-title">Toelichting op deze vraag</span>
                    <span class="information-text">{{ Questions.Items[Questions.Index].Information }}</span>
                    <img class="illustration" src="@/assets/Illustration/Advies.png" alt="Advice">
                </div>
            </div>
            <div class="footer">
                <span v-if="Questions.Index > 0" class="secondary" @click="Questions.Index = Questions.Index - 1">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7071 4.29289C12.0976 4.68342 12.0976 5.31658 11.7071 5.70711L6.41421 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H6.41421L11.7071 18.2929C12.0976 18.6834 12.0976 19.3166 11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071L3.29289 12.7071C3.10536 12.5196 3 12.2652 3 12C3 11.7348 3.10536 11.4804 3.29289 11.2929L10.2929 4.29289C10.6834 3.90237 11.3166 3.90237 11.7071 4.29289Z"></path></g></svg>
                    Terug naar de vorige vraag
                </span>
                <span v-if="Questions.Index == 0" class="secondary" @click="View = 'Information'">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7071 4.29289C12.0976 4.68342 12.0976 5.31658 11.7071 5.70711L6.41421 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H6.41421L11.7071 18.2929C12.0976 18.6834 12.0976 19.3166 11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071L3.29289 12.7071C3.10536 12.5196 3 12.2652 3 12C3 11.7348 3.10536 11.4804 3.29289 11.2929L10.2929 4.29289C10.6834 3.90237 11.3166 3.90237 11.7071 4.29289Z"></path></g></svg>
                    Terug naar de informatie
                </span>
                <span class="primary" v-if="Questions.Index < Questions.Items.length - 1" :class="{ enabled: Questions.Items[Questions.Index].Answer }" @click="Questions.Index = Questions.Index + 1">
                    Volgende vraag
                </span>
                <span class="primary" v-if="Questions.Index == Questions.Items.length - 1" :class="{ enabled: Questions.Items[Questions.Index].Answer }" @click="SaveAssessment">
                    Afronden
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressBar from 'primevue/progressbar';
import { useToast } from "vue-toastification";

const toast = useToast()

export default {
    name: 'Create',

    components: {
        DataTable,
        Column,
        ProgressBar
    },

    data () {
        return {
            View: 'Information',
            User: [],
            Client: {
                Name: this.$route.params.name,
                ID: '',
                Street: '',
                City: '',
                IBAN: this.$route.params.iban
            },
            KVK: {
                Loader: false,
                Items: false,
                Filters: {
                    global: {
                        value: ''
                    }
                }
            },
            Questions: {
                Index: 0,
                Loader: false,
                Items: []
            },
            Duplicates: {
                Button: 'Volgende stap',
                Items: []
            }
        }
    },

    mounted () {
        this.GetProfile()
        this.GetQuestions()
    },

    methods: {
        GetProfile () {
            this.$http.get('https://bouwbond.app/api/v1/auth/profile').then((response) => {
                this.User = response.data.client
            })
        },

        SearchKVK () {
            if(this.Client.Name) {
                this.$root.SetLoader(true)
                this.$http.get('https://bouwbond.app/api/v1/import/kvk', {
                    params: {
                        Search: this.Client.Name
                    }
                }).then((response) => {
                    if(response.data.results) {
                        this.KVK.Items = response.data.results
                    } else {
                        this.KVK.Items = false

                        toast.warning("Geen resultaten gevonden", {
                            position: "top-right",
                            timeout: 2500,
                            closeOnClick: true,
                            pauseOnHover: true,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            icon: true
                        })
                    }
    
                    this.$root.SetLoader(false)
                })
            } else {
                toast.warning("Geef een zoekterm op", {
                    position: "top-right",
                    timeout: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    icon: true
                })
            }
        },

        SelectKVK (e) {
            this.Client.ID = e.data.ID
            this.Client.Name = e.data.Name
            this.Client.Street = e.data.Street
            this.Client.City = e.data.City
            this.KVK.Items = false
        },

        GetQuestions () {
            this.$root.SetLoader(true)
            this.$http.get('https://bouwbond.app/api/v1/assessments/questions').then((response) => {
                if(response.data.questions) { this.Questions.Items = response.data.questions }

                this.$root.SetLoader(false)
            })
        },

        CheckAssessments () {
            if(this.Duplicates.Items.length > 0) {
                this.View = 'Questions'
            } else {
                this.$root.SetLoader(true)
                this.$http.get('https://bouwbond.app/api/v1/assessments/duplicates', {
                    params: {
                        ID: this.Client.ID
                    }
                }).then((response) => {
                    if(response.data.duplicates) {
                        this.Duplicates.Items = response.data.duplicates
                        this.Duplicates.Button = 'Nieuwe aanmaken'
                    } else {
                        this.View = 'Questions'
                    }
    
                    this.$root.SetLoader(false)
                })
            }
        },

        ConnectAssessment (e) {
            this.$root.SetLoader(true)
            this.$http.post('https://bouwbond.app/api/v1/assessments/connect', {
                IBAN: this.$route.params.iban,
                Assessment: e.data.Identifier
            }).then((response) => {
                if(response.data.assessment) {
                    this.$router.push('/opdrachten/' + response.data.assessment)
                } else {
                    toast.warning("Kan niet worden gekoppeld. Probeer het nog een keer.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                    this.$root.SetLoader(false)
                }
            })
        },

        SaveAssessment () {
            this.$root.SetLoader(true)
            this.$http.post('https://bouwbond.app/api/v1/assessments', {
                Client: this.Client,
                Items: this.Questions.Items
            }).then((response) => {
                if(response.data.identifier) {
                    this.$router.push('/opdrachten/' + response.data.identifier)
                } else {
                    toast.warning("Scan kan niet worden opgeslagen. Probeer het nog een keer.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                    this.$root.SetLoader(false)
                }
            })
        }
    }
}
</script>

<style scoped>
.scan .tab {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: calc(100% - 50px);
    gap: 50px;
}

.scan .tab .block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 35px 25px 50px 25px;
    background: #fff;
    border: solid 1px #eff0f6;
    border-radius: 5px;
}

.scan .tab .block .title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 30px;
}

.scan .tab .block .title svg {
    width: 30px;
    height: 30 px;
    stroke: #333;
}

.scan .tab .block .notice {
    width: 100%;
    padding: 10px 15px;
    background: #fff2d9;
    border-radius: 10px;
}

.scan .tab .block .group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px 15px;
}

.scan .tab .block .group.half {
    width: calc(50% - 12.5px);
}

.scan .tab .block .group .subtitle {
    width: 100%;
    font-weight: 600;
}

.scan .tab .block .group .search {
    margin: 0 0 15px 0;
}

.scan .tab .block .group input {
    width: 100%;
    padding: 0 15px;
    height: 50px;
    background: #fafafa;
    border: 0;
    border-radius: 5px;
}

.scan .tab .block .group input.interaction {
    width: calc(100% - 215px);
}

.scan .tab .block .group input:disabled {
    color: #b8b8b8;
}

.scan .tab .block .group:deep(.p-datatable) {
    width: 100%;
}

.scan .tab .block .group:deep(.p-datatable-paginator-bottom) {
    border: 0;
}

.scan .tab .block .group .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background: #ff8c00;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.scan .tab .block .group .button.disabled {
    pointer-events: none;
    opacity: 0.5;
}





.scan .tab .question {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 25px 50px 25px;
    background: #fff;
    border: solid 1px #eff0f6;
    border-radius: 5px;
}

.scan .tab .question .progress {
    width: 100%;
    margin: 0 0 25px 0;
}

.scan .tab .question .bubble {
    width: fit-content;
    padding: 2.5px 15px;
    margin: 50px 0 15px 0;
    background: #009fe3;
    color: #fff;
    font-weight: 600;
    border-radius: 15px;
}

.scan .tab .question .question-title {
    font-weight: 600;
    font-size: 24px;
}

.scan .tab .question .question-text {
    max-width: 800px;
    font-size: 30px;
    line-height: 40px;
}

.scan .tab .question .answers {
    display: flex;
    gap: 15px;
    margin: 50px 0 0 0;
}

.scan .tab .question .answers .answer, .scan .tab .question .answers .more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 140px;
    height: 60px;
    font-weight: 600;
    border: solid 1px #d6d6d6;
    border-radius: 5px;
    cursor: pointer;
}

.scan .tab .question .answers .more {
    width: 200px;
}

.scan .tab .question .answers .more.active {
    background: #cdecf9;
    border-color: #009fe3;
}

.scan .tab .question .answers .more svg {
    width: 28px;
    height: 28px;
    stroke: #333;
}

.scan .tab .question .answers .answer.selected {
    background: #3e3d64;
    color: #fff;
}

.scan .tab .question .information {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 800px;
    margin: 25px 0 0 0;
    padding: 25px;
    background: #cdecf9;
    border: solid 1px #009fe3;
    border-radius: 5px;
}

.scan .tab .question .information .information-title {
    font-weight: 600;
}

.scan .tab .question .information .information-text {
    width: calc(100% - 150px);
}

.scan .tab .question .information .illustration {
    position: absolute;
    bottom: -25px;
    right: -25px;
    width: 200px;
}





.scan .tab .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.scan .tab .footer.paddingBottom {
    padding: 0 0 25px 0;
}

.scan .tab .footer.marginTop {
    margin: 100px 0 0 0;
}

.scan .tab .footer .primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background: #009fe3;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
}

.scan .tab .footer .primary.enabled {
    pointer-events: all;
    opacity: 1;
}

.scan .tab .footer .secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 50px;
    color: #009fe3;
    cursor: pointer;
}

.scan .tab .footer .secondary svg {
    width: 24px;
    height: 24px;
    fill: #009fe3;
}
</style>