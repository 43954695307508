<template>
    <div class="intro">
        <div class="intro-content">
            <span class="title">Oeps, het lijkt erop dat je nog geen toegang hebt tot deze module!</span>
            <span class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu arcu. Nunc eleifend iaculis venenatis. Nunc eu purus auctor, finibus tortor nec, gravida purus. Donec dapibus ullamcorper purus a ultricies. Pellentesque risus lacus, elementum id dui sit amet. Ga naar je Instellingen en verricht de betaling. Je krijgt dan direct toegang tot alle modules en de voordelen van Bouwbond:</span>
            <div class="usp">
                <span class="usp-item">Zonder administratieve kennis wel op de hoogte</span>
                <span class="usp-item">Voorkom boetes of naheffingen van de Belastingdienst</span>
                <span class="usp-item">Ontvang gepersonaliseerde adviezen waar je wat mee kan</span>
                <span class="usp-item">Ontvang gepersonaliseerde adviezen waar je wat mee kan</span>
                <span class="usp-item">Voorkom boetes of naheffingen van de Belastingdienst</span>
            </div>
            <span class="button" @click="this.$root.SetIntro(false)">Krijg direct volledige toegang</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Intro'
}
</script>

<style scoped>
.intro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000080;
    z-index: 888;
}

.intro .intro-content {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: auto;
    padding: 35px;
    background: #fff;
    border-radius: 10px;
}

.intro .intro-content .title {
    font-size: 24px;
    font-weight: 600;
}

.intro .intro-content .usp {
    display: flex;
    flex-direction: column;
}

.intro .intro-content .usp .usp-item {
    display: list-item;
}

.intro .intro-content .button {
    width: fit-content;
    display: flex;
    background: orange;
    padding: 10px 25px;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
}
</style>