<template>
    <div class="transactions">
        <div class="tab">
            <div class="header">
                <span class="item" :class="{ active: View == 'Overview' }" @click="View = 'Overview', Filters.global.value = ''">Overzicht</span>
                <span class="item" :class="{ active: View == 'In' }" @click="View = 'In', Filters.global.value = ''">Inkomend
                    <span class="counter" v-if="Transactions.In.length > 0">{{ Number(Transactions.In.length).toLocaleString(['nl-NL']) }}</span>
                </span>
                <span class="item" :class="{ active: View == 'Out' }" @click="View = 'Out', Filters.global.value = ''">Uitgaand
                    <span class="counter" v-if="Transactions.Out.length > 0">{{ Number(Transactions.Out.length).toLocaleString(['nl-NL']) }}</span>
                </span>
            </div>
            <div class="content padding" v-if="View == 'Overview'">
                <span class="title">MT940 vernieuwen</span>
                <div class="warning" v-if="Transactions.In.length > 0 || Transactions.Out.length > 0">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 10V13" stroke-width="2" stroke-linecap="round"/><path d="M12 16V15.9888" stroke-width="2" stroke-linecap="round"/><path d="M10.2518 5.147L3.6508 17.0287C2.91021 18.3618 3.87415 20 5.39912 20H18.6011C20.126 20 21.09 18.3618 20.3494 17.0287L13.7484 5.147C12.9864 3.77538 11.0138 3.77538 10.2518 5.147Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    <span class="text">Je wilt je MT940 bestand opnieuw uploaden, omdat je huidige versie bijvoorbeeld incompleet of incorrect was. Dat kan! Dat betekent niet dat al je gegevens verloren gaan. Alle transacties die in je nieuwe bestand herkend worden én reeds zijn verwerkt met de Opdracht Scan zullen in veel gevallen automatisch worden aangevuld. Echter kan het voorkomen dat door een wijziging er toch data verloren gaat. Deze informatie moet je dan opnieuw verwerken.</span>
                </div>
                <div class="warning" v-else>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 10V13" stroke-width="2" stroke-linecap="round"/><path d="M12 16V15.9888" stroke-width="2" stroke-linecap="round"/><path d="M10.2518 5.147L3.6508 17.0287C2.91021 18.3618 3.87415 20 5.39912 20H18.6011C20.126 20 21.09 18.3618 20.3494 17.0287L13.7484 5.147C12.9864 3.77538 11.0138 3.77538 10.2518 5.147Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    <span class="text">Upload hieronder uw MT940- of CSV-bestand om een overzicht te krijgen van uw inkomende en uitgaande betalingen. Na het uploaden worden de transacties automatisch geanalyseerd en weergegeven in een overzichtelijke lijst. Zorg ervoor dat uw bestand het juiste formaat heeft (MT940 of CSV). Uw gegevens worden vertrouwelijk behandeld en niet gedeeld met derden.</span>
                </div>
                <label class="upload" for="UploadFile">
                    <button>Selecteer een bestand</button>
                    <input type="file" id="UploadFile" name="filename" @input="UploadFile($event)">
                </label>
            </div>
            <div class="content" v-if="View == 'Uploading'">
                <span class="title">Je MT940 wordt nu geanalyseerd!</span>
                <span class="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu arcu. Nunc eleifend iaculis venenatis. Nunc eu purus auctor, finibus tortor nec, gravida purus. Donec dapibus ullamcorper purus a ultricies. Pellentesque risus lacus, elementum id dui sit amet, molestie porttitor ligula. Suspendisse vestibulum ornare accumsan. Curabitur sed interdum nisl. Aliquam ut ultrices nisl.</span>
            </div>
            <div class="content" v-if="View == 'Uploaded'">
                <span class="title">MT940 verwerkt</span>
            </div>
            <div class="content" v-if="View == 'In' || View == 'Out'">
                <DataTable v-model:filters="Filters" dataKey="Identifier" sortField="Amount" :sortOrder="-1" scrollHeight="flex" v-model:selection="Selection" sortMode="single" scrollable :value="Transactions[View]" showGridlines stripedRows paginator :rows="100" :rowsPerPageOptions="[25, 50, 75, 100]" :globalFilterFields="['IBAN', 'Account']">
                    <template #header>
                        <input class="search" type="text" v-model="Filters.global.value" placeholder="Zoek transacties..." :class="View">
                    </template>
                    <template #empty>Geen transacties gevonden</template>
                    <template #loading>Transacties aan het laden...</template>
                    <Column v-if="View == 'Out'" selectionMode="multiple" headerStyle="width: 10px"></Column>
                    <Column field="IBAN" header="IBAN" sortable></Column>
                    <Column field="Account" header="Naam" sortable></Column>
                    <Column header="#" headerStyle="width: 10px">
                        <template #body="slotProps">
                            {{ slotProps.data.Count }}x
                        </template>
                    </Column>
                    <Column v-if="View == 'In'" field="Amount" header="Euro" sortable>
                        <template #body="slotProps">
                            € {{ Number(slotProps.data.Amount).toLocaleString('nl-NL', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }) }}
                        </template>
                    </Column>
                    <Column v-if="View == 'Out'" field="Amount" header="Euro" sortable>
                        <template #body="slotProps">
                            - € {{ Number(slotProps.data.Amount * -1).toLocaleString('nl-NL', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }) }}
                        </template>
                    </Column>
                    <Column v-if="View == 'Out'" header="%" headerStyle="width: 10px">
                        <template #body="slotProps">
                            {{ ((slotProps.data.Amount * -1) / Transactions.Out.reduce((partialSum, a) => partialSum + Number(a.Amount), 0) * -100).toFixed(2) }}
                        </template>
                    </Column>
                    <Column v-if="View == 'In'" header="%" headerStyle="width: 10px">
                        <template #body="slotProps">
                            {{ (slotProps.data.Amount / Transactions.In.reduce((partialSum, a) => partialSum + Number(a.Amount), 0) * 100).toFixed(2) }}
                        </template>
                    </Column>
                    <Column v-if="View == 'In'" header="Check" sortable>
                        <template #body="slotProps">
                            <router-link :to="'/opdrachten/' + slotProps.data.Assessment" class="link open" v-if="slotProps.data.Assessment">Openen</router-link>
                            <router-link :to="'/opdrachten/aanmaken/' + slotProps.data.Account + '/' + slotProps.data.IBAN + '/' + slotProps.data.Identifier" class="link" v-else>Starten</router-link>
                        </template>
                    </Column>
                    <Column v-if="View == 'Out'" header="Label" sortable>
                        <template #body="slotProps">
                            <span v-if="slotProps.data.LabelName">{{ slotProps.data.LabelName }}</span>
                            <span class="no-label" v-else>Geen</span>
                        </template>
                    </Column>
                    <Column v-if="View == 'In'" field="Date" header="Sinds" sortable>
                        <template #body="slotProps">
                            {{ this.$moment(slotProps.data.Date).format('DD-MM-Y') }}
                        </template>
                    </Column>
                    <Column v-if="View == 'Out'" field="Date" header="Datum" sortable>
                        <template #body="slotProps">
                            {{ this.$moment(slotProps.data.Date).format('DD-MM-Y') }}
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="selection" v-if="Selection.length > 0">
                <span class="title" v-if="Selection.length == 1">1 label toevoegen</span>
                <span class="title" v-if="Selection.length > 1">{{ Selection.length }} labels toevoegen</span>
                <Select v-model="SelectionLabel" :options="SelectionLabels" optionLabel="Name" placeholder="Selecteer een label..." filter highlightOnSelect />
                <span class="save" :class="{ active: SelectionLabel }" @click="SetLabel">Toevoegen
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Select from 'primevue/select';

export default {
    name: 'Transacties',

    components: {
        DataTable,
        Column,
        Select
    },

    data () {
        return {
            Page: 1,
            Limit: 100,
            View: 'Overview',
            Transactions: {
                In: [],
                Out: []
            },
            Selection: [],
            SelectionLabels: [],
            SelectionLabel: false,
            Filters: {
                global: {
                    value: ''
                }
            }
        }
    },

    mounted () {
        this.GetTransactions()
    },

    methods: {
        GetTransactions () {
            this.$root.SetLoader(true)
            this.$http.get('https://bouwbond.app/api/v1/transactions').then((response) => {
                if(response.data.transactions) { this.Transactions = response.data.transactions }
                if(response.data.labels) { this.SelectionLabels = response.data.labels }

                this.$root.SetLoader(false)
            })
        },

        SetLabel () {
            this.$http.post('https://bouwbond.app/api/v1/transactions/label', {
                Label: this.SelectionLabel,
                Selection: this.Selection
            }).then(() => {
                this.Selection.forEach(e => {
                    e.LabelName = this.SelectionLabel.Name
                })

                this.Selection = []
            })
        },

        UploadFile (e) {
            this.View = 'Uploading'
            this.$http.post('https://bouwbond.app/api/v1/transactions/upload', {
                Upload: e.target.files[0]
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.GetTransactions()
                this.View = 'Uploaded'
            })
        }
    }
}
</script>

<style scoped>
.transactions .tab {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    border: solid 1px #eff0f6;
    border-bottom: 0;
    border-radius: 5px;
}

.transactions .header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebebeb;
}

.transactions .header .item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 50%;
    height: 70px;
    font-weight: 600;
    cursor: pointer;
}

.transactions .header .item.active {
    background: #fff;
}

.transactions .header .item .counter {
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 10px;
    background: #3e3d64;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
}

.transactions .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.transactions .content.padding {
    padding: 25px;
}

.transactions .content .title {
    font-size: 24px;
    font-weight: 600;
}

.transactions .content .warning {
    display: flex;
    align-items: center;
    background: #fff797;
    border: solid 1px #ffeb7b;
    border-radius: 10px;
}

.transactions .content .warning svg {
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    margin: 0 0 0 25px;
    stroke: #ffeb7b;
}

.transactions .content .warning .text {
    padding: 25px;
}

.transactions .content .upload {
    padding: 25px;
    border: dashed 1px #009fe3;
    border-radius: 10px;
    cursor: pointer;
}

.transactions .content .upload input[type='file'] {
    display: none;
    overflow: 0;
}

.transactions .content .upload button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 50px;
    background: #009fe3;
    color: #fff;
    border: 0;
    border-radius: 10px;
    pointer-events: none;
}

.transactions .content .list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.transactions .content .list .list-header {
    display: flex;
}

.transactions .content .list .list-header .item {
    width: 20%;
}

.transactions .content .list .list-item {
    display: flex;
}

.transactions .content .list .list-item .item {
    width: 20%;
}

.transactions:deep(.p-datatable) {
    height: 100%;
}

.transactions:deep(.p-datatable-paginator-bottom) {
    border: 0;
    border-top: solid 1px #e2e8f0;
    border-radius: 0;
}

.transactions:deep(.no-label) {
    font-size: 14px;
    opacity: 0.25;
}

.transactions:deep(.link) {
    color: #009fe3;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.transactions:deep(.link.open) {
    color: #008000;
}

.transactions:deep(.p-datatable-header .search) {
    width: 100%;
    border: 0;
    outline: 0;
}

.transactions:deep(.p-datatable-header .search.In) {
    padding: 5px;
}

.transactions:deep(.p-datatable-header .search.Out) {
    padding: 5px 25px 5px 40px;
}

.transactions:deep(.p-datatable-header) {
    padding: 15px;
    border: 0;
    border-top: solid 1px #e2e8f0;
}

.transactions:deep(.p-datatable-header-cell:first-of-type) {
    padding-left: 15px;
    border-left: 0;
}

.transactions:deep(.p-datatable-tbody > tr > td:first-of-type) {
    padding-left: 15px;
    border-left: 0;
}

.transactions:deep(.p-datatable-thead > tr > th:last-child) {
    border-right: 0;
}

.transactions:deep(.p-datatable-tbody > tr > td:last-child) {
    border-right: 0;
}

.transactions:deep(.p-checkbox-checked .p-checkbox-icon) {
    stroke: #fff;
}

.transactions .selection {
    position: fixed;
    bottom: 100px;
    right: 50px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 10px #0000000f;
}

.transactions .selection .title {
    font-weight: 600;
}

.transactions .selection .save {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background: #d1d1d1;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0.25;
}

.transactions .selection .save.active {
    background: #0fb981;
    pointer-events: all;
    cursor: pointer;
    opacity: 1;
}

.transactions .selection .save svg {
    width: 24px;
    height: 24px;
}
</style>